import React , { useState , Fragment } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from './CardModal'

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import imgbg from '../../assets/images/backgroup-secsion/img_bg_page_title.jpg'
import imgbg1 from '../../assets/images/backgroup-secsion/ser1.jpeg'
import imgbg2 from '../../assets/images/backgroup-secsion/ser2.jpeg'
import imgbg3 from '../../assets/images/backgroup-secsion/ser3.jpeg'
import imgbg4 from '../../assets/images/backgroup-secsion/ser4.jpeg'
import imgstake from '../../assets/images/backgroup-secsion/stake.jpg'

const LiveAuction = props => {
    const data = props.data;

    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgbg1} alt="Axies" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">- Avaride – World’s Digital Currency  </h2>
                                          <p>AVAR token was founded in April 2022 with the sole purpose of building an entire eco-system for merchants and consumers. </p>
                                   <p>AVAR is the most rapid smart contract platform in the blockchain industry. </p>
                                   <p>AVAR is amazingly fast, low cost and eco-friendly. A decentralised token backed by Binance smart chain
Bep20 platform. A decentralized virtual reality token in which the customers and merchants can
transact reliably, cost-effectively, securely and instantly. </p>
                          
                          </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         

         <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">- What is Avaride Token ?</h2>
                                      <p>
     Avaride token is a digital payment system that doesn’t rely on banks to verify transactions; it’s a peer-to-peer system that can enable anyone anywhere to send and secure payments. The term crypto token refers to unique virtual currency token or denominated crypto currencies. These token represent fungible and tradable assets or utilities on their block chains. Crypto token are often used for fundraising for crowd sales, but they can also be used as a substitute for other things.
    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgbg2} alt="Axies" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         

         <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgbg3} alt="Axies" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">- How secure is Avaride Token?</h2>
                                   
                                    <p>Avaride is a new crypto token built using BINANCE Blockchain. It's a reasonably complex technical process. The digital ledger which contains the results of each and every crypto-transactions that's hard for hackers to tamper with, because of it's secured smart contract on Binance Bep20 platform.</p>
                                    <p>Avaride is an open source smart contract deployed on Binance Blockchain (smart chain). Every transaction done for this token will be recorded on blockchain ledgers and is visible to everybody. Binance blockchain works on POS (Proof of stake), the consensus mechanism used to validate block transactions.</p>
                                    <p>Validators maintain PoS – powered blockchains. The validation process in PoS is called “Forging”. If a node intends to participate in the block creation process, it simple has to stake the native token. There is no need to spend on electricity or purchase specialized hardware.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
            <div className="tf-section tf-item-details">
                    <div className="themesflat-container">
                        <div className="row">
                            
                            <div className="col-xl-6 col-md-12">
                                <div className="content-right">
                                    <div className="sc-item-details">
                                        <h2 className="style2">- Storing of Avaride TOKEN </h2>
                                          <p>If you buy digital currency, one has to store it. Avaride token can be stored in a digital crypto currency wallet. Each has its own benefits and technical security requirement- for example, trust wallet, metamask, etc. While there are many different wallets, Avaride token can be stored in any cryptocurrency wallet supporting Binance blockchain technology.</p>
                                          <h2 className="style2">What is A Token sale?</h2>
                                          <p>A token sale sometimes referred to as ICO, is a form of crowdfunding for digital currency- related projects. In token sales, purchasers buy units of a new currency in exchange for legal tender or other digital currencies, such as Bitcoin or Ethereum. </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className="content-left">
                                    <div className="media">
                                        <img src={imgbg4} alt="Axies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             

                    <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgstake} alt="Axies"
                                     style={{
                      width: "500px",
                      borderRadius: "40px",
                      marginBottom: "40px",

                    }}
                                     />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">- STAKING </h2>
                                    <p>Avaride gives you stake option so that you can Earn more.</p>

                                   <p>
                                       <ul>
                                       <li>1. Stake 100 to 49999 AVAR and get 15% annually of your AVAR stake</li>
                                       <li>2. Stake 50000 to 99999 AVAR and get 18% annually of your AVAR stake</li>
                                       <li>3. Stake above 1,00,000 AVAR and get 20% annually of your AVAR stake..</li>

                                       </ul>
                                   </p>
                                   <p>Earn more with your Avaride ( AVAR)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
        
    );
}

LiveAuction.propTypes = {
    data: PropTypes.array.isRequired,
}


export default LiveAuction;
    