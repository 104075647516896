import React from "react";
import { Link, Route, Navigate } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Button, Input } from "reactstrap";
import axios from "../axios";

class Login extends React.Component {

  // state = {
  //   email: null,
  //   password: null,
  // }

  // login = () => {
  //   alert("login clicked");
  // };

  login(e) {
    e.preventDefault();
    let request = {
      email: document.getElementById("email").value,
    };
    axios
      .post("/user/forget-password", request)
      .then((response) => {
        if(response.data.status === 1) {
          alert("Please check your mail!");
        } else {
          alert("Please try again!");
        }
        
        
      })
      .catch((err) => {
        // console.log(err); 
        // if (err.response.status === 500) {
        //   alert("Server error, please try again later!");
        // }
      });
  }

  // signup = () => {
  //     this.context.router.history.push('/sign-up');
  // };

  render() {
    return (
      <div>
        <Header />
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">Forget Password</h1>
                </div>
                <div className="breadcrumbs style2">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Forget Password</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <div className="flat-form box-login-email">
            

                  <div className="form-inner">
                    <form onSubmit={(e) => this.login(e)}>
                    <Input
                            style={{ marginTop: "30px", marginBottom: "30px" }}
                            id="email"
                            name="email"
                            placeholder="Your Email Address"
                            type="email"
                            required
                            // onChange={(event) =>
                            //   this.setState({ email: event.target.value })
                            // }
                          />{" "}
                       
                      <Button className="sc-button exp style2"
                        style={{
                          fontSize: "20px",
                          color:"white",
                        }}
                        // onClick={() => this.login()}
                      >
                        Submit
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
};

export default Login;
