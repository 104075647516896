import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import imgstake from '../assets/images/backgroup-secsion/stake.jpg';


import img1 from '../assets/images/blog/thumb-8.png'

const Contact01 = () => {
    return (
        <div>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">About us </h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>About us </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>
            
            <LiveAuction data={liveAuctionData} />
            
                    <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgstake} alt="Axies"
                                     style={{
                      width: "500px",
                      borderRadius: "40px",
                      marginBottom: "40px",

                    }}
                                     />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">- STAKING </h2>
                                    <p>Avaride gives you stake option so that you can Earn more.</p>

                                   <p>
                                       <ul>
                                       <li>1. Stake 100 to 49999 AVAR and get 15% annually of your AVAR stake</li>
                                       <li>2. Stake 50000 to 99999 AVAR and get 18% annually of your AVAR stake</li>
                                       <li>3. Stake above 1,00,000 AVAR and get 20% annually of your AVAR stake..</li>

                                       </ul>
                                   </p>
                                   <p>Earn more with your Avaride ( AVAR)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Contact01;
