import React, { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom'
const FooterUser = () => {
   

   
      
    return (
        <div>
            <footer id="footer" className="footer-light-style clearfix bg-style">
                          <script src="/assets/jquery-1.11.2.min.js.download"></script>
                      <script src="/assets/App.js.download"></script>
                      <script src="/assets/AppNavigation.js.download"></script>

            </footer>
        </div>

    );
}

export default FooterUser;
