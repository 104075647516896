const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
         
    },
    {
        id: 2,
        name: 'About us',
        links: '/About',

    },
    {
        id: 3,
        name: 'Service',
        links: '/Service',
    },
    // {
    //     id: 4,
    //     name: 'Platform',
    //     links: '#',
    
    // },
    // {
    //     id: 5,
    //     name: 'White Paper',
    //     links: '../assets/pdf/AVARIDE.1.pdf',
     
    // },
    {
        id: 7,
        name: 'Contact',
        links: '/contact-01',
       
    },
    
]

export default menus;