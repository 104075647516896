import React,{ useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header_User';
import Footer from '../../components/footer/Footer_User.jsx';
import liveAuctionData from '../../assets/fake-data/data-live-auction';
import LiveAuction from '../../components/layouts/LiveAuction';
import img1 from '../../assets/images/blog/thumb-8.png'
import logo_half from '../../assets_user/avatar1x.png'
import axios from "../../axios";
import Moment from 'moment';

function RefferalList() {
   
   const [recordCount, setRecordCount] = useState(0);
   const [perPage, setPerPage] = useState(25);
   const [search, setSearch] = useState("");
   const refer_by = localStorage.getItem("id");
   const [refers, setRefers] = useState([]);

   React.useEffect(() => {
      axios.get("/user/my-referral" ,{
         params: {
            perPage: `${perPage}`,
            search: `${search}`,
            refer_by: `${refer_by}`
         }
       }).then((response) => {
         // console.log(response.data.data);
         setRefers(response.data.data);
         setRecordCount(response.data.data.length);
         // recordCount2 = recordCount;
       });
    }, []);

   return (
      <div>
         <Header />
         <div id="base">
            <div className="offcanvas">
            </div>
            <link type="text/css" rel="stylesheet" href="assets/AdminLTE.css"/>
              <div id="content">
                  <section>
                     <div className="section-body">
                        <div id="wrning-modal" className="modal-warning modal fade"  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                           <div className="modal-dialog">
                              <form name="warnigform" id="warnigform" action="my_directs_list">
                                 <div className="modal-content">
                                    <div className="modal-header">
                                       <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                       <h3 className="modal-title" id="myModalLabel"> <i className="fa fa-exclamation-triangle fa-lg"></i> Warning!</h3>
                                    </div>
                                    <div className="modal-body edit-content">
                                       <h4 id="modmsg_war"></h4>
                                       <input type="hidden" id="hidwarid" value="" />
                                    </div>
                                    <div className="modal-footer">
                                       <button type="button" className="btn btn-danger pull-left" data-dismiss="modal" > No </button>
                                       <button type="button" className="btn btn-success"> Yes </button>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-md-12">
                              <div className="card card-underline">
                                 <div className="card-head card-head style-info  text-center">
                                    <header className="opacity-100">My Referer</header>
                                 </div>
                                 <div className="card-body">
                                    <div className="">
                                       <form action="https://promoter.bighbull.org/userpanel/by_option_direct_dist_list" method="post" accept-charset="utf-8">
                                          <div className="col-lg-2 no-padding">
                                             <div className="form-group">
                                                <div className="control-group row-fluid form-inline">
                                                   <label className="control-label">Page View </label>
                                                   <select name="showrecordcount" className="select form-control" id="showrecordfsp" onChange={(e) => setPerPage(e.target.value)}>
                                                      <option value="5">5</option>
                                                      <option value="25" selected="selected">25</option>
                                                      <option value="50">50</option>
                                                      <option value="100">100</option>
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                    <h6 className="text-gray opacity-50 text-right"> <i>Record Count : ( {recordCount} )</i></h6>
                                    <div className="row">
                                       <div className="col-lg-12">
                                          <div className="box box-solid">
                                             <div className="box-body">
                                                <table className="table table-bordered table-hover table-responsive">
                                                   <thead className="style-primary">
                                                      <tr className="small bg-blue">
                                                         <td className="text-center">
                                                            Sr.no
                                                         </td>
                                                         <td className="text-center">
                                                            User ID/Name
                                                         </td>
                                                         <td className="text-center">
                                                            Join Date
                                                         </td>
                                                         <td className="text-center">
                                                            Email
                                                         </td>
                                                         <td className="text-center">
                                                            Mobile
                                                         </td>
                                                         {/* <td className="text-center">
                                                             Token Buy Rate                                                
                                                         </td>
                                                         <td className="text-center">
                                                            No. of Total  Token
                                                         </td> */}
                                                      </tr>
                                                   </thead>
                                                   {refers.map((refer,i) => <Referral key={i} referal={refer} />)}
                                                     </table>
                                                <div className="text-center">
                                                   <p className="text-center"></p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>
              
            <div id="menubar" className="menubar-inverse  animate">
               <div className="menubar-fixed-panel">
                  <div>
                     <a className="btn btn-icon-toggle btn-default menubar-toggle" data-toggle="menubar" href="#">
                     <i className="fa fa-bars"></i>
                     </a>
                  </div>
                  <div className="expanded">
                     <a href="#/home">
                     <span className="text-lg text-bold text-primary ">USER PANEL</span>
                     </a>
                  </div>
               </div>
               <div className="nano has-scrollbar" >
                  <div className="nano-content" >
                     <div className="menubar-scroll-panel" >
                        <ul id="main-menu" className="gui-controls">
                           <li>
                              <a href="/User/Dashboard">
                                 <div className="gui-icon"><button type="button" className="btn ink-reaction btn-floating-action btn-warning">
                                    <i className="fa fa-dashboard"></i></button>
                                 </div>
                                 <span className="title">Dashboard</span>
                              </a>
                           </li>
                           <li className="gui-folder">
                              <a>
                                 <div className="gui-icon"><i className="fa fa-user text-primary-light"></i></div>
                                 <span className="title">Personal Settings</span>
                              </a>
                              <ul >
                                 <li><a href="View_edit_profile"><span className="title"> View/Edit Profile</span></a></li>
                                 <li><a href="Change_password"><span className="title">Change Sign In Password</span></a></li>
                                 <li><a href="Change_secret_password"><span className="title">Change Transaction Password</span></a></li>
                              </ul>
                           </li>
                           <li>
                              <a href="#">
                                 <div className="gui-icon"><span className="fa fa-circle-o text-warning"></span></div>
                                 <span className="title">Stake AVAR Tokens</span>
                              </a>
                           </li>
                    
                           <li className="gui-folder">
                              <a>
                                 <div className="gui-icon"><i className="fa fa-sitemap text-primary"></i></div>
                                 <span className="title">My Team</span>
                              </a>
                              <ul >
                                 <li><a href="My_affilates"><span className="title">My Referers</span></a></li>
                              </ul>
                           </li>
                           <li className="gui-folder">
                              <a>
                                 <div className="gui-icon"><i className="fa fa-weixin text-info"></i></div>
                                 <span className="title">
                                 Support Center 
                                 <span className="label bg-info"> 2</span>
                                 </span>
                              </a>
                              <ul >
                                 <li><a href="Compose_ticket"><span className="title">Compose Support Ticket</span></a></li>
                                 <li><a href="Support_ticket_summary"><span className="title">Support Ticket Summary</span></a></li>
                              </ul>
                           </li>
                        </ul>
                        <div className="menubar-foot-panel">
                           <small className="no-linebreak hidden-folded">
                           <span className="opacity-75">Copyright © 2020</span> <strong><a href="https://avaridetoken.com/">WWW.avaridetoken.com</a></strong>
                           </small>
                        </div>
                     </div>
                  </div>
                  <div className="nano-pane">
                     <div className="nano-slider" ></div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
      );
}
function Referral(props){
      return <tbody className="small" key={props.referal._id}>
                                                            
         <tr>
            <td className="text-center text">
               {props.referal._id}                                                      
            </td>
            <td className="">
               [ <span className="text-primary">{props.referal.referral_code}</span> ] 
               <span>{ props.referal.firstname }</span> <span className="strong">{ props.referal.lastname }</span>                                                         
            </td>
            <td className="text-center">
               [{ Moment(props.referal.createdAt).format('DD/MM/YYYY')}]
            </td>
            <td className=" text-center">
               <span className="text-primary small">{props.referal.email}</span>
            </td>
            <td className=" text-center">
               <span className="text-primary small">{props.referal.country_code} {props.referal.mobile_no}</span>
            </td>
            {/* <td className=" text-center">
               0 <span className="text-primary small">INR</span>
            </td>
            <td className="text-center">
               <span className="">0</span>  
            </td> */}
         </tr>
      </tbody>
}

export default RefferalList;