import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header_User';
import Footer from '../../components/footer/Footer_User.jsx';
import liveAuctionData from '../../assets/fake-data/data-live-auction';
import LiveAuction from '../../components/layouts/LiveAuction';
import img1 from '../../assets/images/blog/thumb-8.png'
import logo_half from '../../assets_user/avatar1x.png'
const view_edit_profile = () => {
return (
<div>
   <Header />
   <div id="base">
      <div className="offcanvas">
      </div>
      <link type="text/css" rel="stylesheet" href="assets/AdminLTE.css"/>
       <div id="content">
               <div className="section-body">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card card-underline">
                           <div className="card-head card-head style-info text-center">
                              <header className="opacity-100">Zaakpay Transaction Summary</header>
                           </div>
                           <div className="card-body">
                              <div >
                                 <form action="https://promoter.bighbull.org/userpanel/by_option_tran_summary_token_zaakpay" method="post" accept-charset="utf-8">
                                    <div className="col-lg-1 no-padding">
                                       <div className="form-group">
                                          <div className="control-group row-fluid form-inline">
                                             <label  className="control-label">Page View</label>
                                             <select name="showrecordcount" className="select form-control" id="showrecordfsp">
                                                <option >5</option>
                                                <option >10</option>
                                                <option >15</option>
                                                <option >25</option>
                                                <option >50</option>
                                                <option >100</option>
                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-3 no-padding">
                                       <div className="col-sm-3 no-padding text-left">
                                          <label><input type="radio" name="listoptions" /> All Trans &nbsp;&nbsp;</label>
                                       </div>
                                       <div className="col-sm-3 no-padding text-right">
                                          <label><input type="radio" name="listoptions"  /> Order id :&nbsp;&nbsp; </label>
                                       </div>
                                       <div className="col-sm-12 no-padding">
                                          <input type="text" name="keyword"  id="keyword" className="form-control" placeholder="Search: Order id"   />
                                       </div>
                                    </div>
                                    <div className="col-md-2">
                                       <div className="">
                                          <label><input type="radio" name="listoptions"  /> </label>
                                          <label >Only Date : From <span className="text-danger"><small> <i className="fa fa-asterisk"></i></small></span>
                                          </label>
                                          <div className="input-group date form_date col-md-12" data-date="" data-date-format="dd MM yyyy" data-link-field="dtp_input1">
                                             <span className="input-group-addon"><i className="fa fa-calendar"></i></span> 

                                             <input type="text" name="txtDtpickr" id="txtDtpickr"  className="form-control" placeholder="Click to select Date-Time"  />
                                             <span className="input-group-addon"><span className="glyphicon glyphicon-remove"></span></span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-3">
                                       <div className="">
                                          <label> : To Date <span className="text-danger"><small> <i className="fa fa-asterisk"></i></small></span>
                                          </label>
                                          <div className="input-group date form_date col-md-12" data-date="" data-date-format="dd MM yyyy" data-link-field="dtp_input1">
                                             <span className="input-group-addon"><i className="fa fa-calendar"></i></span> 

                                             <input type="text" name="txtDtpickr2" id="txtDtpickr2"  className="form-control" placeholder="Click to select Date-Time" />
                                             <span className="input-group-addon"><span className="glyphicon glyphicon-remove"></span></span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-1 no-padding">
                                       <button type="submit" className="btn btn-primary pull-right"><i className="fa fa-search" aria-hidden="true"></i> Search</button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="card card-underline">
                           <div className="card-body" >
                              <h6 className="text-gray opacity-50 text-right" > <i>Record Count : ( 3 )</i></h6>
                              <table className="table table-bordered  table-hover table-responsive">
                                 <thead className="style-warning">
                                    <tr>
                                       <th className="col-md-1">Sr.No</th>
                                       <th className="col-md-2 text-center"> Date &amp; Time</th>
                                       <th className="col-md-1 text-center">Order No.</th>
                                       <th className="col-md-1 text-center">Amount</th>
                                       <th className="col-md-6"> Description</th>
                                       <th className="col-md-6"> Status</th>
                                    </tr>
                                 </thead>
                                 <tbody className="red">
                                    <tr>
                                       <td> 1 </td>
                                       <td>
                                          <span>2021-12-12 10:49:31</span>
                                       </td>
                                       <td> ZPLive1639286309719</td>
                                       <td> 1000.00</td>
                                       <td>
                                          <span>Customer cancelled transaction. Transaction has failed</span>
                                       </td>
                                       <td> <span className="red">Failed</span>
                                       </td>
                                    </tr>
                                 </tbody>
                                 <tbody className="red">
                                    <tr>
                                       <td> 2 </td>
                                       <td>
                                          <span>2022-01-11 23:58:02</span>
                                       </td>
                                       <td> ZPLive1641925665465</td>
                                       <td> 0.00</td>
                                       <td>
                                          <span >Transaction is Processing...!</span>
                                       </td>
                                       <td> <span className="red">Failed</span>
                                       </td>
                                    </tr>
                                 </tbody>
                                 <tbody className="red">
                                    <tr>
                                       <td> 3 </td>
                                       <td>
                                          <span>2022-01-11 23:58:56</span>
                                       </td>
                                       <td> ZPLive1641925696685</td>
                                       <td> 1000.00</td>
                                       <td>
                                          <span>Customer cancelled transaction. Transaction has failed</span>
                                       </td>
                                       <td> <span className="red">Failed</span>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                              <div className="text-center">
                                 <p className="text-center"></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


       </div>
         
      <div id="menubar" className="menubar-inverse  animate">
         <div className="menubar-fixed-panel">
            <div>
               <a className="btn btn-icon-toggle btn-default menubar-toggle" data-toggle="menubar" href="#">
               <i className="fa fa-bars"></i>
               </a>
            </div>
            <div className="expanded">
               <a href="#/home">
               <span className="text-lg text-bold text-primary ">USER PANEL</span>
               </a>
            </div>
         </div>
         <div className="nano has-scrollbar" >
            <div className="nano-content" >
               <div className="menubar-scroll-panel" >
                  <ul id="main-menu" className="gui-controls">
                     <li>
                        <a href="/User/Dashboard">
                           <div className="gui-icon"><button type="button" className="btn ink-reaction btn-floating-action btn-warning">
                              <i className="fa fa-dashboard"></i></button>
                           </div>
                           <span className="title">Dashboard</span>
                        </a>
                     </li>
                     <li className="gui-folder">
                        <a>
                           <div className="gui-icon"><i className="fa fa-user text-primary-light"></i></div>
                           <span className="title">Personal Settings</span>
                        </a>
                        <ul >
                           <li><a href="View_edit_profile"><span className="title"> View/Edit Profile</span></a></li>
                           <li><a href="Change_password"><span className="title">Change Sign In Password</span></a></li>
                           <li><a href="Change_secret_password"><span className="title">Change Transaction Password</span></a></li>
                        </ul>
                     </li>
                     <li>
                        <a href="#">
                           <div className="gui-icon"><span className="fa fa-circle-o text-warning"></span></div>
                           <span className="title">Stake AVAR Tokens</span>
                        </a>
                     </li>
               
                     <li className="gui-folder">
                        <a>
                           <div className="gui-icon"><i className="fa fa-sitemap text-primary"></i></div>
                           <span className="title">My Team</span>
                        </a>
                        <ul >
                           <li><a href="My_affilates"><span className="title">My Referer</span></a></li>
                        </ul>
                     </li>
                     <li className="gui-folder">
                        <a>
                           <div className="gui-icon"><i className="fa fa-weixin text-info"></i></div>
                           <span className="title">
                           Support Center 
                           <span className="label bg-info"> 2</span>
                           </span>
                        </a>
                        <ul >
                           <li><a href="Compose_ticket"><span className="title">Compose Support Ticket</span></a></li>
                           <li><a href="Support_ticket_summary"><span className="title">Support Ticket Summary</span></a></li>
                        </ul>
                     </li>
                  </ul>
                  <div className="menubar-foot-panel">
                     <small className="no-linebreak hidden-folded">
                     <span className="opacity-75">Copyright © 2020</span> <strong><a href="https://avaridetoken.com/">WWW.avaridetoken.com</a></strong>
                     </small>
                  </div>
               </div>
            </div>
            <div className="nano-pane">
               <div className="nano-slider" ></div>
            </div>
         </div>
      </div>
   </div>
   <Footer />
</div>
);
}
export default view_edit_profile;