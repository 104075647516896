import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { web3, currentAccount } from "../web3";
import ava from "../ava";
import axios from "../axios";
import copy from "copy-to-clipboard";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Input,
  Col,
} from "reactstrap";
// const aggregatorV3InterfaceABI = [
//   {
//     inputs: [],
//     name: "decimals",
//     outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     inputs: [],
//     name: "description",
//     outputs: [{ internalType: "string", name: "", type: "string" }],
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
//     name: "getRoundData",
//     outputs: [
//       { internalType: "uint80", name: "roundId", type: "uint80" },
//       { internalType: "int256", name: "answer", type: "int256" },
//       { internalType: "uint256", name: "startedAt", type: "uint256" },
//       { internalType: "uint256", name: "updatedAt", type: "uint256" },
//       { internalType: "uint80", name: "answeredInRound", type: "uint80" },
//     ],
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     inputs: [],
//     name: "latestRoundData",
//     outputs: [
//       { internalType: "uint80", name: "roundId", type: "uint80" },
//       { internalType: "int256", name: "answer", type: "int256" },
//       { internalType: "uint256", name: "startedAt", type: "uint256" },
//       { internalType: "uint256", name: "updatedAt", type: "uint256" },
//       { internalType: "uint80", name: "answeredInRound", type: "uint80" },
//     ],
//     stateMutability: "view",
//     type: "function",
//   },
//   {
//     inputs: [],
//     name: "version",
//     outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
//     stateMutability: "view",
//     type: "function",
//   },
// ];
// const addr = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";

const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "description",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
    name: "getRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];
const addr = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE"; //mainnet address
// const addr = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526"; //testnet address

class Buy extends React.Component {



  currentLocation = () => {
    // const {pathname} = this.props.location;
    const pathnameurl = window.location.href;
    const lastPart = pathnameurl.split("/").pop();
    // console.log(lastPart);

    if (lastPart != "buy") {
      // this.setState({ urlRefferal: lastPart });
      let filterArr = lastPart.split("?");
      return filterArr[0];
    } else {
      return null;
    }
    return "";
  }
  state = {
    eth: 0,
    myAddress: currentAccount,
    success: null,
    refAddress: null,
    error: null,
    tokenqty: 0.0,
    tokenPrice: 0,
    bnb_usd: 0,
    urlRefferal: this.currentLocation(),
  };

  async componentDidMount() {
    // if (typeof window !== undefined && window.ethereum !== undefined && window.ethereum.isTrust !== undefined && window.ethereum.isTrust == true) {

    // } else {
    //   // in the server OR user is not running Trust Wallet
    //   alert("Connect with your Trust Wallet");
    // }

    web3.eth.getAccounts().then((address) => this.setState({ myAddress: address[0] }));
    this.setState({ tokenPrice: await ava.methods.token_rate.call().call() });
    const priceFeed = new web3.eth.Contract(aggregatorV3InterfaceABI, addr);
    priceFeed.methods
      .latestRoundData()
      .call()
      .then((roundData) => {
        this.setState({ bnb_usd: parseInt((roundData[1] / 10 ** 8).toString().split(".")[0]) });
      });
      if (this.state.refAddress === null || this.state.refAddress === "") {
        this.setState({ refAddress: this.state.urlRefferal });
      }
  }

  // async componentDidMount() {
  //   if (typeof window !== undefined && window.ethereum !== undefined && window.ethereum.isTrust !== undefined && window.ethereum.isTrust == true) {

  //   } else {
  //     // in the server OR user is not running Trust Wallet
  //     alert("Connect with your Trust Wallet");
  //   }
  //   let x;
  //   web3.eth.getAccounts().then((addr) => this.setState({ myAddress: addr }));
  //   // await ava.methods.token_rate.call(function(err, res){
  //   //   // this.setState({tokenPrice: res})
  //   //   // x = res.toString();
  //   //   console.log(res);
  //   // });
  //   x = await ava.methods.token_rate.call().call();
  //   console.log(x/(10**18));
  //   this.setState({ tokenPrice: x / 10 ** 18 });
  //   const priceFeed = new web3.eth.Contract(aggregatorV3InterfaceABI, addr);
  //   priceFeed.methods
  //     .latestRoundData()
  //     .call()
  //     .then((roundData) => {
  //       this.setState({ bnb_usd: parseInt((roundData[1] / 10 ** 8).toString().split(".")[0]) });
  //       console.log("Latest Round Data", roundData[1] / 10 ** 8);
  //     });
  //     if (this.state.refAddress === null || this.state.refAddress === "") {
  //       this.setState({ refAddress: this.state.urlRefferal });
  //     }
  // }

  buyAVA = async () => {
    const accounts = await web3.eth.getAccounts();
    let tokenqty;
    let amountEntered;

    if (this.state.eth === 0) {
      alert("Please enter BNB amount");
    } else if (this.state.refAddress != null && this.state.eth === 0) {
      alert("Please enter BNB amount");
    }
    if(accounts[0] == undefined){
      alert("Kindly reload to connect with wallet");
      return false;
    }
    // console.log(accounts[0], this.state.eth, this.state.refAddress);
    if ((this.state.refAddress === null || this.state.refAddress.length === 0) && this.state.eth != 0) {
      amountEntered = web3.utils.toWei(this.state.eth, "ether");

      if(this.state.myAddress == null || this.state.myAddress == undefined){
        this.setState({ myAddress: accounts[0] });
      }
      try {
        
        tokenqty = await ava.methods.buytokensDapp_Noref().send({
          // from: accounts[0],
          from: this.state.myAddress,
          // value: amountEntered,
          value: this.state.eth,
        });
        this.setState({ success: true, tokenqty: tokenqty });
      } catch (e) {
        this.setState({ success: false, error: e });
        console.log(this.state.error);
      }
      console.log(this.state.refAddress);
    } else if (
      this.state.refAddress != null &&
      web3.utils.isAddress(this.state.refAddress) &&
      this.state.eth != 0
    ) {
      // console.log("Ref called", this.state.refAddress);
      amountEntered = web3.utils.toWei(this.state.eth, "ether");
      try {
        tokenqty = await ava.methods
          .buytokensDapp_ref(this.state.refAddress)
          .send({
            // from: accounts[0],
            // value: amountEntered,
            from: this.state.myAddress,
            value: this.state.eth,
          });
        this.setState({ success: true, tokenqty: tokenqty });
      } catch (e) {
        this.setState({ success: false, error: e });
        console.log(this.state.error);
      }
    } else if (
      this.state.refAddress != null && this.state.refAddress.length != 0 &&
      web3.utils.isAddress(this.state.refAddress) === false
    ) {
      this.setState({ success: false, error: "Invalid referral address!" });
      alert(this.state.error);
    }


  }


  render() {
    console.log("url", this.state.urlRefferal, "Ref", this.state.refAddress);
    
    var avar = 0.0;
    let requested;
    let refInput;
    // if (this.state.urlRefferal === null || this.state.urlRefferal === "" || this.state.refAddress === null || this.state.refAddress === "") {
    if (this.state.urlRefferal === null || this.state.urlRefferal === "") {
      refInput = (<Input
        style={{ marginTop: "30px", marginBottom: "30px" }}
        id="refAddress"
        name="refAddress"
        placeholder="Enter the referral address, if any"
        type="text"
        // value={this.state.urlRefferal}
        onChange={(event) =>
          this.setState({ refAddress: event.target.value })
        }
      />);
    } else {
      refInput = (<Input
        style={{ marginTop: "30px", marginBottom: "30px" }}
        id="refAddress"
        name="refAddress"
        placeholder="Enter the referral address, if any"
        type="text"
        value={this.state.urlRefferal}
        onChange={(event) =>
          this.setState({ refAddress: event.target.value })
        }
      />);
    }
    if (this.state.eth != 0) {
      // requested = web3.utils.toWei(this.state.eth, "ether");
      // // alert(requested + ' ' + this.state.bnb_usd + ' ' + this.state.tokenPrice);
     
      // avar =
      //   (requested * this.state.bnb_usd) / (this.state.tokenPrice * 10 ** 18);
    
      requested = parseFloat(web3.utils.toWei(this.state.eth, "ether")); // Convert to parseFloat to avoid precision issues

      // Check if this.state.bnb_usd and this.state.tokenPrice are valid numbers
      // if (typeof this.state.bnb_usd !== 'number' || typeof this.state.tokenPrice !== 'number') {
      //   console.error('Invalid bnb_usd or tokenPrice');
      // } else {
        let bnb_usd = this.state.bnb_usd;
        let tokenPrice = this.state.tokenPrice;

        console.log(requested); // Check the value of requested
        console.log(typeof requested); // Check the type of requested
        console.log(bnb_usd); // Check the value of bnb_usd
        console.log(typeof bnb_usd); // Check the type of bnb_usd
        console.log(tokenPrice); // Check the value of tokenPrice
        console.log(typeof tokenPrice); // Check the type of tokenPrice

        if((parseFloat(tokenPrice) * parseFloat(10) ** parseFloat(18)) > 0){
          avar = (requested * parseFloat(bnb_usd)) / (parseFloat(tokenPrice) * parseFloat(10) ** parseFloat(18));
        }

        console.log(avar.toString()); // Output: NaN if any input is invalid
      // }


      
      console.log(requested + ' ' + avar + ' ' + this.state.bnb_usd + ' ' + this.state.tokenPrice);
    }
    let successMessage;
    console.log("adress: " + this.state.refAddress);
    console.log("Token Quantity: " + this.state.tokenqty);
    console.log("Token Price: " + this.state.tokenPrice);
    if (this.state.success === true) {
      successMessage = (
        <Col xl="8">
          <Card
            style={{
              backgroundImage:
                "linear-gradient(to right, #ddd6f3 35%, #faaca8 100%)",
              padding: "10px",
              margin: "40px",
              marginLeft: "470px",
            }}
            inverse
          >
            <CardBody style={{ color: "black" }}>
              <CardTitle tag="h5">Success!</CardTitle>
              <CardText style={{ color: "black" }}>
                You have received AVAR. Please check your wallet!
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
    } else if (this.state.success === false) {
      successMessage = (
        <Col xl="8">
          <Card
            style={{
              backgroundImage:
                "linear-gradient(to right, #ddd6f3 35%, #faaca8 100%)",
              padding: "10px",
              margin: "40px",
              marginLeft: "470px",
            }}
            inverse
          >
            <CardBody style={{ color: "black" }}>
              <CardTitle tag="h5">Error!</CardTitle>
              <CardText style={{ color: "black" }}>
                Error, you have not received any AVAR. Please try again!
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
    }
    const copyToClipboard = () => {
      copy(document.getElementById("copy2").innerText);
      alert(`You have copied "${document.getElementById("copy2").innerText}"`);
    }


    // this.currentLocation();

    return (
      <div>
        <Header />
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">Buy AVAR</h1>
                </div>
                <div className="breadcrumbs style2">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Buy</li>
                  </ul>
                </div>
                <div>
                  <Col xl="10">
                    <Card className="buyclass"

                      inverse
                    >

                      <CardBody style={{ color: "black" }}>
                        <CardTitle tag="h5">AVAR/BNB <span style={{ float: "right" }}
                        >Current Avar Price : {this.state.tokenPrice / 10 ** 18}</span></CardTitle>
                        <CardText>
                          {" "}
                          {refInput}
                          {" "}
                          <Input
                            style={{ marginTop: "30px", marginBottom: "30px" }}
                            id="eth"
                            name="eth"
                            required
                            placeholder="Enter your BNB amount"
                            type="number"onChange={(event) =>
                              this.setState({
                                eth: web3.utils.toWei(
                                  event.target.value,
                                  "ether"
                                ),
                              })
                            }
                          />{" "}
                        </CardText>
                        <CardText>
                          <h4 style={{
                              color: "Black",
                              marginBottom: "0px"
                            }}>{(this.state.tokenPrice > 0)?(`Avar Token ≈ `+avar):``}

                            <Button
                              style={{
                                backgroundImage:
                                  "linear-gradient(to right, #aa076b 30%, #61045f 100%)",
                                width: "auto",
                                height: "40px",
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "white",
                                float: "right"
                              }}
                              onClick={() => this.buyAVA()}
                            >
                              {(this.state.tokenPrice > 0)?(`Buy`):`Proceed`}
                            </Button>

                          </h4>
                        </CardText>

                        {(this.state.myAddress != undefined)?
                        (<CardText>
                          {/* <div> */}
                            <p style={{ color: "Black", marginTop: "40px", fontSize: "15px", wordBreak: "break-all", cursor: "pointer" }}>Refer Link : </p>
                            <p id="copy2" style={{ color: "Black", marginTop: "40px", fontSize: "15px", wordBreak: "break-all", cursor: "pointer" }}>
                              https://avaridetoken.com/buy/{this.state.myAddress}
                            </p>
                            <Button
                              style={{
                                backgroundImage: "linear-gradient(to right, #aa076b 30%, #61045f 100%)",
                                width: "146px",
                                height: "30px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                color: "white",
                                float: "right"
                              }}
                              onClick={copyToClipboard}
                            >
                              Copy to Clipboard
                            </Button>
                          {/* </div> */}
                        </CardText>):null};


                      </CardBody>
                    </Card>
                  </Col>
                  {successMessage}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Buy;