import React, { useRef, useState, useEffect } from "react";
import { Link, Route, Navigate } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Button, Input } from "reactstrap";
import axios from "../axios";

class Login extends React.Component {

  // state = {
  //   email: null,
  //   password: null,
  // }

  // login = () => {
  //   alert("login clicked");
  // };
 
login(e) {
    e.preventDefault();
    let request = {
      email: document.getElementById("email").value,
      password: document.getElementById("pwd").value,
    };
    axios
      .post("/user/login", request)
      .then((response) => {
        if(response.data.data.status === 1) {
          // console.log(response.data.data.auth_token);
          localStorage.setItem("token", response.data.data.auth_token);
          localStorage.setItem("id", response.data.data._id);
          // this.context.router.history.push('/buy');
          // return <Link to="/buy"></Link>
          window.location.href = '/User/Dashboard';
          // this.props.history.push("/buy");
          // document.location.reload(); ////////////----------??????
        } else {
          alert("Please try again!");
        }
        
        
      })
      .catch((err) => {
        // console.log(err); 
        // if (err.response.status === 500) {
        //   alert("Server error, please try again later!");
        // }
      });
  }

  // signup = () => {
  //     this.context.router.history.push('/sign-up');
  // };

  render() {
    return (
      <div>
        <Header />
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">Login</h1>
                </div>
                <div className="breadcrumbs style2">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Login</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*</section>
          <section className="tf-login tf-section"> */}
          <div className="themesflat-container">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                {/* <h2 className="tf-title-heading ct style-1">
                                        Login
                                       </h2>  */}

                {/*<div className="flat-form box-login-social">
                                        <div className="box-title-login">
                                            <h5>Login with social</h5>
                                        </div>
                                        <ul>
                                            <li>
                                                <Link to="#" className="sc-button style-2 fl-button pri-3">
                                                    <i className="icon-fl-google-2"></i>
                                                    <span>Google</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#" className="sc-button style-2 fl-button pri-3">
                                                    <i className="icon-fl-facebook"></i>
                                                    <span>Facebook</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}

                <div className="flat-form box-login-email">
                  {/*<div className="box-title-login">
                                            <h5>Or login with email</h5>
                                        </div> */}

                  <div className="form-inner">
                    <form onSubmit={(e) => this.login(e)}>
                    <Input
                            style={{ marginTop: "30px", marginBottom: "30px" }}
                            id="email"
                            name="email"
                            placeholder="Your Email Address"
                            type="email"
                            // onChange={(event) =>
                            //   this.setState({ email: event.target.value })
                            // }
                          />{" "}
                          <Input
                          style={{ marginTop: "30px", marginBottom: "30px" }}
                          id="pwd"
                          name="pwd"
                          placeholder="Enter Password"
                          type="password"
                          // onChange={(event) =>
                          //   this.setState({ password: event.target.value })
                          // }
                        />{" "}
                      <div className="row-form style-1">
                        <label>
                          Remember me
                          <input type="checkbox" />
                          <span className="btn-checkbox"></span>
                        </label>
                        <Link to="/Forgetpassword" className="forgot-pass">
                          Forgot Password ?
                        </Link>
                      </div>
                      <Button
                      className="sc-button exp style2"
                        style={{
                          fontSize: "20px",
                          color:"white",
                        }}
                        // onClick={() => this.login()}
                      >
                        Login
                      </Button>

                    </form>
                    <br/>
                    <Link to="/sign-up"><Button className="sc-button exp style2"
                        style={{
                          fontSize: "20px",
                          color:"white",
                        }}
                    // onClick={() => this.signup()}
                    >
                      Signup
                    </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
};

export default Login;
