import React from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../assets/images/icon/Wallet.png'
import icon2 from '../../assets/images/icon/Category.png'
import icon3 from '../../assets/images/icon/Image2.png'
import icon4 from '../../assets/images/icon/Bookmark.png'

const Create = () => {
    const data = [
        // {
        //     title: "Avaride – India’s Digital Currency",
        //     description: "Avaride Token is an indian digital currency that will be used as a transaction taken o any Avaride technosoft LLP, Copy, and bot trading platforms. In addition to that, it will be the base Token of the crypto exchanger COTOEX being launched by Avaride technosoft LLP for crypto trading.",
        //     icon : icon1,
        //     colorbg : "icon-color1"
        // },
        {
            title: "What is Avaride Token ?",
            description: "Avaride Token is a digital payment system that doesn’t rely on banks to verify transactions; it’s a peer-to-peer system that can enable anyone anywhere to send and secure payments. The term crypto Token refers to unique virtual currency Token or denominated crypto currencies.",
            icon : icon2,
            colorbg : "icon-color2"
        },
        {
            title: "How secure is Avaride Token ?",
            description: "Avaride is a new crypto token built using BINANCE block chain technology. It’s a reasonably complex, technical process. Still, the result is a digital ledger of crypto transactions that’s hard for hackers to tamper with. It has a  contract on Binance",
            icon : icon3,
            colorbg : "icon-color3"
        },
        {
            title: "Validators maintain PoS powered blockchains ",
            description: "The validation process in PoS is called 'Forging'. If a node intends to participate in the block creation process, it simply has to stake the native token. There is no need to spend on electricity or purchase specialized hardware.",
            icon : icon4,
            colorbg : "icon-color4"
        },
    ]
    return (
        <section className="tf-box-icon create style1 tf-section">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions mg-bt-22">
                            <h2 className="tf-title pb-17">
                                Services</h2>                         
                        </div>
                    </div>
                    {
                        data.map((item , index) => (
                            <CreateItem key={index} item={item} />
                        ))
                    }
                </div>                 
            </div>
        </section>
    );
}

const CreateItem = props => (
    <div className='col-lg-3 col-md-6 col-12'>
        <div className="sc-box-icon">
            <div className="image">
                <div className={`icon-create ${props.item.colorbg}`}>
                    <img src={props.item.icon} alt="" />
                </div>                                                                             
            </div>
            <h3 className="heading"><Link to="/wallet-connect">{props.item.title}</Link></h3>
            <p className="content">{props.item.description}</p>
        </div>
    </div>
)

export default Create;
