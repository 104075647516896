import Web3 from "web3";

let web3;
let currentAccount;

if (typeof window !== "undefined" && window.ethereum) {
  // MetaMask or compatible provider is available
  web3 = new Web3(window.ethereum);

  // Request account access if needed
  window.ethereum.enable()
    .then(accounts => {
      // Set current account
      currentAccount = accounts[0];
      // Reload page when connected
      // window.location.reload();
    })
    .catch(error => {
      // User denied account access
      console.error(error);
    });

  // Handle account changes
  window.ethereum.on('accountsChanged', handleAccountsChanged);

  // Handle chain changes
  window.ethereum.on('chainChanged', () => {
    window.location.reload(); // Reload page when chain changes
  });

} else if (typeof window !== "undefined" && window.web3 && window.web3.currentProvider.isTrust) {
  // Trust Wallet is available
  web3 = new Web3(window.web3.currentProvider);
  // Set current account
  currentAccount = web3.eth.defaultAccount;
  // Reload page when connected
  window.location.reload();
} else {
  // Fallback to Binance Smart Chain remote node
  web3 = new Web3('https://bsc-dataseed1.binance.org:443');
}

// Function to handle account changes
function handleAccountsChanged(accounts) {
  if (accounts.length === 0) {
    // MetaMask or Trust Wallet is locked or the user has not connected any accounts
    alert('Please connect to your wallet.');
  } else {
    // Set current account
    currentAccount = accounts[0];
    // Do any other work with the connected account
  }
}

export { web3, currentAccount };
