import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import logoheader from "../../assets_user/logo-6X.png";
import logoheader2x from "../../assets_user/logo-6X.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import logodark2x from "../../assets/images/logo/logo_dark@2x.png";
import imgsun from "../../assets/images/icon/sun.png";
import avt from "../../assets/images/avatar/avt-2.jpg";
import axios from "../../axios";


const Header = () => {
  
  const logout = () => {
    axios
      .post("/user/logout")
      .then((response) => {
        console.log(response);
        localStorage.clear();
        window.location.href = '/login';
      })
      .catch((err) => {
        alert("Error!");
      });
  };

  const { pathname } = useLocation();

  const headerRef = useRef(null);
  useEffect(() => {
    AuthMiddleware();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const btnSearch = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const searchBtn = () => {
    btnSearch.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  return (
    
    <header id="header" style={{ position: "fixed" }}>
      <div className="headerbar">
        <div className="headerbar-left text-right">
          <ul className="header-nav header-nav-options">
            <li className="header-nav-brand">
              <div className="brand-holder">
                <a href="#/home">
                  <img
                    src={logoheader}
                    srcSet={`${logoheader2x}`}
                    width="100"
                    alt="logo"
                  />
                </a>
              </div>
            </li>
            <li>
              <a
                className="btn btn-icon-toggle menubar-toggle"
                data-toggle="menubar"
                href="#"
              >
                <i className="fa fa-bars" id="mymenubar"></i>
              </a>
            </li>
            <li>
              <a href="#/home">
                {" "}
                <button
                  type="button"
                  className="btn ink-reaction btn-floating-action btn-warning btn-xs visible-sm visible-xs"
                >
                  <i className="fa fa-dashboard"></i>{" "}
                </button>
              </a>{" "}
              Ï<div className="text-right"></div>
            </li>
            <span className="pull-right">
              <button
                type="button"
                className="btn ink-reaction btn-floating-action btn-default-light btn-sm"
                onClick={() => logout()}
              >
                <i className="fa fa-power-off text-danger"></i>
              </button>
            </span>
          </ul>
        </div>
      </div>
    </header>
  );
};

function AuthMiddleware() {

  if(localStorage.getItem('token') == null){
    window.location.href = "/login";
    // console.log("test")
  }
}
export default Header;
