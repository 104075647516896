import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Home04 from "./Home04";
import Home05 from "./Home05";
import Home06 from "./Home06";
import Home07 from "./Home07";
import Home08 from "./Home08";
import About from "./About";
import Buy from "./Buy";
import Stake from "./Stake";
import Explore03 from "./Explore03";
import Explore04 from "./Explore04";
import LiveAuctions from "./LiveAuctions";
import ItemDetails01 from "./ItemDetails01";
import ItemDetails02 from "./ItemDetails02";
import Activity01 from "./Activity01";
import Activity02 from "./Activity02";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import HelpCenter from "./HelpCenter";
import Authors01 from "./Authors01";
import Authors02 from "./Authors02";
import WalletConnect from "./WalletConnect";
import CreateItem from "./CreateItem";
import EditProfile from "./EditProfile";
import Ranking from "./Ranking";
import Login from "./Login";
import ForgetPassword from "./ForgetPassword";
import SignUp from "./SignUp";
import NoResult from "./NoResult";
import FAQ from "./FAQ";
import Contact01 from "./Contact01";
import Contact02 from "./Contact02";
import Service from "./Service";
import Partner from "./Partner";
import Dashboard from "./User/Dashboard";
import View_edit_profile from "./User/View_edit_profile";
import Change_password from "./User/Change_password";
import Change_secret_password from "./User/Change_secret_password";
import Mob_quick from "./User/Mob_quick";
import IPPOPAY from "./User/IPPOPAY";
import My_affilates from "./User/My_affilates";
import Support_ticket_summary from "./User/Support_ticket_summary";
import Compose_ticket from "./User/Compose_ticket";



const routes = [
  { path: '/', component: <Home01 />},
  { path: '/home-02', component: <Home02 />},
  { path: '/Partner', component: <Partner />},
  { path: '/Service', component: <Service />},
  { path: '/About', component: <About />},
  { path: '/home-03', component: <Home03 />},
  { path: '/home-04', component: <Home04 />},
  { path: '/home-05', component: <Home05 />},
  { path: '/home-06', component: <Home06 />},
  { path: '/home-07', component: <Home07 />},
  { path: '/home-08', component: <Home08 />},
  { path: '/buy', component: <Buy />},
  { path: '/buy/:token', component: <Buy />},
  { path: '/stake', component: <Stake />},
  { path: '/explore-03', component: <Explore03 />},
  { path: '/explore-04', component: <Explore04 />},
  { path: '/live-auctions', component: <LiveAuctions />},
  { path: '/item-details-01', component: <ItemDetails01 />},
  { path: '/item-details-02', component: <ItemDetails02 />},
  { path: '/activity-01', component: <Activity01 />},
  { path: '/activity-02', component: <Activity02 />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/authors-01', component: <Authors01 />},
  { path: '/authors-02', component: <Authors02 />},
  { path: '/wallet-connect', component: <WalletConnect />},
  { path: '/create-item', component: <CreateItem />},
  { path: '/edit-profile', component: <EditProfile />},
  { path: '/ranking', component: <Ranking />},
  { path: '/login', component: <Login />},
  { path: '/ForgetPassword', component: <ForgetPassword />},
  { path: '/sign-up', component: <SignUp />},
  { path: '/no-result', component: <NoResult />},
  { path: '/faq', component: <FAQ />},
  { path: '/contact-01', component: <Contact01 />},
  { path: '/contact-02', component: <Contact02 />},
  { path: '/User/Dashboard', component: <Dashboard />},
  { path: '/User/View_edit_profile', component: <View_edit_profile />},
  { path: '/User/Change_password', component: <Change_password />},
  { path: '/User/Change_secret_password', component: <Change_secret_password />},
  { path: '/User/Mob_quick', component: <Mob_quick />},
  { path: '/User/IPPOPAY', component: <IPPOPAY />},
  { path: '/User/My_affilates', component: <My_affilates />},
  { path: '/User/Compose_ticket', component: <Compose_ticket />},
  { path: '/User/Support_ticket_summary', component: <Support_ticket_summary />},

]

export default routes;