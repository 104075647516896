import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header_User";
import Footer from "../../components/footer/Footer_User.jsx";
import liveAuctionData from "../../assets/fake-data/data-live-auction";
import LiveAuction from "../../components/layouts/LiveAuction";
import img1 from "../../assets/images/blog/thumb-8.png";
import logo_half from "../../assets_user/avatar1x.png";
import ava from "../../ava";
import axios from "../../axios";

class Dashboard extends React.Component {
  state = {
    tokenPrice: 0,
    userEmail: null,
    userFName: null,
    userLName: null,
  };
  
  async componentDidMount() {
    
    let x;
    x = await ava.methods.token_rate.call().call();
    // x = x.toString();
    //  console.log(x);
    this.setState({ tokenPrice: x / 10 ** 18 });
  }
  getUser = async () =>{
   
    axios.get("/user/" + localStorage.getItem("id"),{
        params: {
          _id: localStorage.getItem("id"),
        }
      }).then((response) => {
        // console.log(response.data.data);
        localStorage.setItem("user-detail", response.data.data);
        this.setState({
          userEmail: response.data.data.email,
          userFName: response.data.data.firstname,
          userLName: response.data.data.lastname,
      }).catch((err) => {
        // console.log(err); 
        if (err.response.status === 500) {
          alert("Server error, please try again later!");
        }
      });
      // console.log(this.state.userEmail);
    });
  }
  render() {
    return (
      <div>
        <Header />
        <div id="base" onLoad={() => this.getUser()}>
          <div className="offcanvas"></div>
          <link type="text/css" rel="stylesheet" href="assets/AdminLTE.css" />
          <div id="content">
            <section className="full-bleed">
              <div className="section-body style-default-dark force-padding text-shadow">
                <div className="img-backdrop"></div>
                <div className="overlay overlay-shade-top stick-top-left height-3"></div>
                <div className="row">
                  <div className="col-md-4 col-xs-5"></div>
                </div>
                <div className="row">
                  <div className="col-md-10 col-xs-6">
                    <img
                      className="img-responsivex auto-width logo_half"
                      src={logo_half}
                      alt=""
                    />

                    <h3>
                    {localStorage.getItem("id")}
                      <br /> {this.state.userFName} {" "} {this.state.userLName}
                      <br />
                      <small>{this.state.userEmail}</small>
                    </h3>
                  </div>
                  <div className="col-md-2 col-xs-6 text-center">
                    <h1 className="text-info bg-dark">
                      {/*<b>{this.state.tokenPrice} BNB</b>*/}
<b>$ {this.state.tokenPrice} </b>
                    </h1>
                    {/*<div className="price">
                      <b>
                        <i className="fa fa-inr"> </i> 28.65
                      </b>
    </div>*/}
                    <h5 className="h5">Current Token Rate</h5>
                  </div>
                </div>
                <div className="overlay overlay-shade-bottom stick-bottom-left force-padding text-right">
                  <a
                    className="btn btn-icon-toggle text-warning"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="My Direct"
                    href="#/pre_link/initial_my_directs_list"
                  >
                    <i className="fa fa-sitemap"></i>
                  </a>
                  <a
                    className="btn btn-icon-toggle text-warning"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Edit profile"
                    href="#/pre_link/initial_edit_profile"
                  >
                    <i className="md md-edit"></i>
                  </a>
                </div>
              </div>
            </section>
            <section>
              <div className="section-body no-margin">
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <h4>Buy BNB token (Using Cryptocurrency)</h4>
                    </div>
                    <div className="card">
                      <div className="card-head">
                        <ul
                          className="nav nav-tabs tabs-info"
                          data-toggle="tabs"
                        >
                          <li>
                            <a href="#/home#load4"> BNB</a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body tab-content">
                        <div className="tab-pane active" id="load4">
                          <h5>Buy AVAR Using Binance Coin:</h5>
                          <h2 className="text-center">BNB</h2>
                          <h5 className="text-center">
                            <a className="text-warning" href="/buy">
                              <i className="fa fa-circle fa-lg"></i> Buy AVAR
                              Now
                            </a>
                          </h5>
                        </div>
                        <div className="tab-pane" id="load6">
                          <h5>Buy AVAR Using USDT:</h5>
                          <h2 className="text-center">USDT(TRC20)</h2>
                          <h5 className="text-center">
                            <a
                              className="text-info"
                              href="#/pre_link/initial_set_load_coin_USDT_amount"
                            >
                              <i className="fa fa-circle fa-lg"></i> Buy AVAR
                              Now
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h4>My Bonus</h4>
                    </div>
                    <div className="card">
                      <div className="card-head">
                        <ul
                          className="nav nav-tabs tabs-info"
                          data-toggle="tabs"
                        >
                          <li className="active">
                            <a href="#/home#inc1">Affiliate Bonus</a>
                          </li>
                          <li>
                            <a href="#/home#inc2">Staking Bonus</a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body tab-content">
                        <div className="tab-pane active" id="inc1">
                          <h5>Affiliate Bonus:</h5>
                          <h2 className="text-center">
                            <i className="fa fa-inr"> </i> 0{" "}
                          </h2>
                          <h5 className="text-center text-info">
                            <a href="#/by_option_tran_summary_token/AFFIL">
                              View
                            </a>
                          </h5>
                        </div>
                        <div className="tab-pane" id="inc2">
                          <h5>Staking Bonus:</h5>
                          <h2 className="text-center">
                            0 <small className="text-warning">AVAR</small>
                          </h2>
                          <h5 className="text-center text-info">
                            <a href="#/by_option_tran_summary_token/TOKROS">
                              View
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
                <script src="assets/clipboard.min.js.download"></script>

                <div className="row">
                  <div className="col-md-6"></div>
                </div>
              </div>
            </section>
          </div>
          <div id="menubar" className="menubar-inverse  animate">
            <div className="menubar-fixed-panel">
              <div>
                <a
                  className="btn btn-icon-toggle btn-default menubar-toggle"
                  data-toggle="menubar"
                  href="#"
                >
                  <i className="fa fa-bars"></i>
                </a>
              </div>
              <div className="expanded">
                <a href="#/home">
                  <span className="text-lg text-bold text-primary ">
                    USER PANEL
                  </span>
                </a>
              </div>
            </div>
            <div className="nano has-scrollbar">
              <div className="nano-content">
                <div className="menubar-scroll-panel">
                  <ul id="main-menu" className="gui-controls">
                    <li>
                      <a href="/User/Dashboard">
                        <div className="gui-icon">
                          <button
                            type="button"
                            className="btn ink-reaction btn-floating-action btn-warning"
                          >
                            <i className="fa fa-dashboard"></i>
                          </button>
                        </div>
                        <span className="title">Dashboard</span>
                      </a>
                    </li>
                    <li className="gui-folder">
                      <a>
                        <div className="gui-icon">
                          <i className="fa fa-user text-primary-light"></i>
                        </div>
                        <span className="title">Personal Settings</span>
                      </a>
                      <ul>
                        <li>
                          <a href="View_edit_profile">
                            <span className="title"> View/Edit Profile</span>
                          </a>
                        </li>
                        <li>
                          <a href="Change_password">
                            <span className="title">
                              Change Sign In Password
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="Change_secret_password">
                            <span className="title">
                              Change Transaction Password
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/stake">
                        <div className="gui-icon">
                          <span className="fa fa-circle-o text-warning"></span>
                        </div>
                        <span className="title">Stake AVAR Tokens</span>
                      </a>
                    </li>
                    <li className="gui-folder">
                      <a>
                        <div className="gui-icon">
                          <i className="fa fa-sitemap text-primary"></i>
                        </div>
                        <span className="title">My Team</span>
                      </a>
                      <ul>
                        <li>
                          <a href="My_affilates">
                            <span className="title">My Referer</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="gui-folder">
                      <a>
                        <div className="gui-icon">
                          <i className="fa fa-weixin text-info"></i>
                        </div>
                        <span className="title">
                          Support Center
                          <span className="label bg-info"> 2</span>
                        </span>
                      </a>
                      <ul>
                        <li>
                          <a href="Compose_ticket">
                            <span className="title">
                              Compose Support Ticket
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="Support_ticket_summary">
                            <span className="title">
                              Support Ticket Summary
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="menubar-foot-panel">
                    <small className="no-linebreak hidden-folded">
                      <span className="opacity-75">Copyright © 2020</span>{" "}
                      <strong>
                        <a href="https://avaridetoken.com/">
                          WWW.avaridetoken.com
                        </a>
                      </strong>
                    </small>
                  </div>
                </div>
              </div>
              <div className="nano-pane">
                <div className="nano-slider"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Dashboard;
