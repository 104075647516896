import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header_User';
import Footer from '../../components/footer/Footer_User.jsx';
import liveAuctionData from '../../assets/fake-data/data-live-auction';
import LiveAuction from '../../components/layouts/LiveAuction';
import img1 from '../../assets/images/blog/thumb-8.png'
import logo_half from '../../assets_user/avatar1x.png'
import axios from "../../axios";

function updateProfile (e) {
   // console.log("fire");
   e.preventDefault();
   let request = {
     transaction_password: document.getElementById("tranpassword").value,
     _id: localStorage.getItem("id"),
   };
   axios
     .post("/user/profile-update", request)
     .then((response) => {
       if(response.data.data.status === 1) {
         alert("Current changes updated!");
       } else {
         alert("Please try again!");
       }
       
       
     })
     .catch((err) => {
       // console.log(err); 
       // if (err.response.status === 500) {
       //   alert("Server error, please try again later!");
       // }
     });
 }

const view_edit_profile = () => {
   

return (
<div>
   <Header />
   <div id="base">
      <div className="offcanvas">
      </div>
      <link type="text/css" rel="stylesheet" href="assets/AdminLTE.css"/>
      <div id="content">
         <section>
            <div className="section-body">
               <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                     <div className="text-center">
                        <span className="fa-stack fa-4x">
                        <i className="fa fa-lock text-info"></i>
                        </span>
                     </div>
                     <div className="card card-underline">
                        <div className="card-head card-head style-primary-dark opacity-75 text-center">
                           <header className="opacity-100"> Transaction Password</header>
                        </div>
                        <div className="card-body">
                           <form action="" name="tranpassform" id="tranpassform" className="form" method="post" onSubmit={(e) => updateProfile(e)}>
                              <div className="box-footer">
                                 <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-8">
                                       <div className="form-group floating-label" >
                                          <input type="password" name="tranpassword" id="tranpassword" className="form-control" required/>
                                          <label for="tranpassword">Provide Transaction Password
                                          <span className="text-danger"><b>*</b></span>
                                          </label>
                                       </div>
                                    </div>
                                    <div className="col-sm-3 text-center" >
                                       <button type="submit" className="btn btn-success" id="btntranpass" > Submit </button>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-3"></div>
               </div>
            </div>
         </section>
      </div>
      <div id="menubar" className="menubar-inverse  animate">
         <div className="menubar-fixed-panel">
            <div>
               <a className="btn btn-icon-toggle btn-default menubar-toggle" data-toggle="menubar" href="#">
               <i className="fa fa-bars"></i>
               </a>
            </div>
            <div className="expanded">
               <a href="#/home">
               <span className="text-lg text-bold text-primary ">USER PANEL</span>
               </a>
            </div>
         </div>
         <div className="nano has-scrollbar" >
            <div className="nano-content" >
               <div className="menubar-scroll-panel" >
                  <ul id="main-menu" className="gui-controls">
                     <li>
                        <a href="/User/Dashboard">
                           <div className="gui-icon"><button type="button" className="btn ink-reaction btn-floating-action btn-warning">
                              <i className="fa fa-dashboard"></i></button>
                           </div>
                           <span className="title">Dashboard</span>
                        </a>
                     </li>
                     <li className="gui-folder">
                        <a>
                           <div className="gui-icon"><i className="fa fa-user text-primary-light"></i></div>
                           <span className="title">Personal Settings</span>
                        </a>
                        <ul >
                           <li><a href="View_edit_profile"><span className="title"> View/Edit Profile</span></a></li>
                           <li><a href="Change_password"><span className="title">Change Sign In Password</span></a></li>
                           <li><a href="Change_secret_password"><span className="title">Change Transaction Password</span></a></li>
                        </ul>
                     </li>
                     <li>
                        <a href="#">
                           <div className="gui-icon"><span className="fa fa-circle-o text-warning"></span></div>
                           <span className="title">Stake AVAR Tokens</span>
                        </a>
                     </li>
           
                     <li className="gui-folder">
                        <a>
                           <div className="gui-icon"><i className="fa fa-sitemap text-primary"></i></div>
                           <span className="title">My Team</span>
                        </a>
                        <ul >
                           <li><a href="My_affilates"><span className="title">My Referer</span></a></li>
                        </ul>
                     </li>
                     <li className="gui-folder">
                        <a>
                           <div className="gui-icon"><i className="fa fa-weixin text-info"></i></div>
                           <span className="title">
                           Support Center 
                           <span className="label bg-info"> 2</span>
                           </span>
                        </a>
                        <ul >
                           <li><a href="Compose_ticket"><span className="title">Compose Support Ticket</span></a></li>
                           <li><a href="Support_ticket_summary"><span className="title">Support Ticket Summary</span></a></li>
                        </ul>
                     </li>
                  </ul>
                  <div className="menubar-foot-panel">
                     <small className="no-linebreak hidden-folded">
                     <span className="opacity-75">Copyright © 2020</span> <strong><a href="https://avaridetoken.com/">WWW.avaridetoken.com</a></strong>
                     </small>
                  </div>
               </div>
            </div>
            <div className="nano-pane">
               <div className="nano-slider" ></div>
            </div>
         </div>
      </div>
   </div>
   <Footer />
</div>
);
}
export default view_edit_profile;